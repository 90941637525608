<template>
    <div class="collocation-wrapper">
        <a-card :bordered='false'>
            <div class="form-title">
            <div>已选{{selectedRowKeys.length}}</div>
            <div>
                <a-button type="primary" :disabled="selectedRowKeys.length<=0" @click="handleClickSendMore">群发</a-button>
            </div>
            </div>
            <a-table 
             :columns="columns"
             :data-source="tableData"
             :row-key="(record) => record._id"
             :loading='loading'
             :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: onSelectChange}"
             :pagination="false">
                <template slot="avatar"
                            slot-scope="text">
                        <a-avatar :src="text"/>
                </template>
                
            </a-table>
        </a-card>
        <a-modal v-model="show" title="群发" okText="发送" width="1000px" :afterClose="handleClose" @ok="handleClickSend">
            <div class="more-title">
               tips: 因不同素材的大小，和微信的接收规则,最后的顺序有可能会改变
            </div>
            <div class="main">
                <div class="left">
                    <a-form >
                        <a-form-item 
                            label="客户标签" 
                            :label-col="{span:6}" 
                            :wrapper-col="{ span: 14 }"
                            >
                        <a-tree-select
                                v-model="arrList"
                                style="width: 100%"
                                :tree-data="tagTree"
                                tree-checkable
                                :show-checked-strategy="SHOW_PARENT"
                                placeholder="请选择"
                                :replaceFields="{title:'name',key:'id',value:'id'}"
                                @change="getSeleted"
                            />
                        </a-form-item>
                    </a-form>
                
                    <div>
                        <a-radio-group v-model="type" :style="{ marginBottom: '8px' }" @change="onChange">
                            <a-radio-button value="write">
                                手动输入
                            </a-radio-button>
                            <a-radio-button value="select">
                                使用素材
                            </a-radio-button>
                        </a-radio-group>
                    </div>
                    <div v-if="type==='write'">
                        <a-textarea
                            v-model="sendText"
                            placeholder="请输入内容"
                            :maxLength="250"
                            :auto-size="{ minRows: 5 }"
                            :disabled="activeTab==='sendProduct'"
                            /> 
                    </div>
                    <div v-else>
                        <div class="tab-view">
                            <div class="tab-item" :class="{'active':activeTab==='sendText'}" @click="handleChangeTab('sendText')">文本</div>
                            <div class="tab-item" :class="{'active':activeTab==='sendImage'}" @click="handleChangeTab('sendImage')">图片</div>
                            <div class="tab-item" :class="{'active':activeTab==='sendVideo'}" @click="handleChangeTab('sendVideo')">视频</div>
                            <div class="tab-item" :class="{'active':activeTab==='sendLink'}" @click="handleChangeTab('sendLink')">链接</div>
                            <div class="tab-item" :class="{'active':activeTab==='sendApp'}" @click="handleChangeTab('sendApp')">小程序</div>
                            <div class="tab-item" :class="{'active':activeTab==='sendProduct'}" @click="handleChangeTab('sendProduct')">商品</div>

                        </div>
                        <a-input-search
                            placeholder="标题"
                            enter-button="搜索"
                            size="large"
                            @search="handleClickSearch"
                            />
                        <div class="text-view tab-content" v-if="activeTab==='sendText'">
                            <div class="text-item" v-for="(item,index) in resList" :key="index">
                                <div class="text-title">{{item.title}}</div>
                                <div class="text-desc">{{item.content}}</div>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="img-view tab-content" v-if="activeTab==='sendImage'">
                            <div class="img-item" v-for="(item,index) in resList" :key="index">
                                <img :src="item.content" class="img"/>
                                <p class="title">{{item.title}}</p>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="img-view tab-content" v-if="activeTab==='sendVideo'">
                            <div class="img-item" v-for="(item,index) in resList" :key="index">
                                <video :src="item.content" class="img" ></video>
                                <p class="title">{{item.title}}</p>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="link-view tab-content" v-if="activeTab==='sendLink'">
                            <div class="link-item" v-for="(item,index) in resList" :key="index">
                                <div class="title">{{item.content.title}}</div>
                                <div class="content">
                                    <div class="desc">{{item.content.desc}}</div>
                                    <img :src="item.content.image_url" class="link-img"/>
                                </div>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="app-view tab-content" v-if="activeTab==='sendApp'">
                            <div class="app-item" v-for="(item,index) in resList" :key="index">
                                <div class="title">
                                    <img :src="item.content.thumburl" class="logo-img"/>
                                    <span class="app-name">{{item.content.title}}</span>
                                </div>
                                <div class="content">
                                    <p class="desc">{{item.content.des || '   '}}</p>
                                    <img :src="item.content.image" class="main-img"/>
                                </div>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="product-view tab-content" v-if="activeTab==='sendProduct'">
                            <div class="product-item" v-for="(item,index) in resList" :key="index">
                                <div class="content">
                                    <p class="desc">{{item.description || '   '}}</p>
                                    <img :src="item.attachments && item.attachments[0].url" class="main-img"/>
                                </div>
                                <button class="use-btn" @click="handleClickUse(item)">选择</button>
                            </div>
                        </div>
                        <div class="btn-view">
                            <button class="btn" :disabled="!hasPrev" @click="handleClickPrev">上一页</button>
                            <button class="btn" :disabled="!hasNext" @click="handleClickNext">下一页</button>
                        </div>
                    </div>     
                </div>
                <div class="right">
                    <div class="phone">
                        <div class="phone-title">凯撒</div>
                        <div class="phone-content">
                            <div class="item" v-for="(item,index) in msgList" :key="index">
                                <div class="self-msg msg-item" >
                                <!-- <div class="content"> -->
                                    <div class="msg" v-if="item.type=='sendText'">{{item.content}}</div>
                                    <img v-else-if="item.type=='sendImage'" :src="item.content" class="message-img"/>
                                    <div v-else-if="item.type=='sendLink'" class="link-content">
                                        <div class="title">{{item.content.title}}</div>
                                        <div class="content">
                                            <div class="desc">{{item.content.desc}}</div>
                                            <img :src="item.content.image_url" class="link-img"/>
                                        </div>
                                    </div>
                                    <video v-else-if="item.type=='sendVideo'" controls :src="item.content" style="width:150px"></video>
                                    <audio v-else-if="item.type=='sendAudio'" preload controls :src="item.content" style="width:150px"></audio>
                                    <div v-else-if="item.type=='sendApp'" class="app-content">
                                        <div class="title">
                                            <img :src="item.content.thumburl" class="logo-img"/>
                                            <span class="app-name">{{item.content.title}}</span>
                                        </div>
                                        <div class="content">
                                            <p class="desc">{{item.content.des || '   '}}</p>
                                            <img :src="item.content.image" class="main-img"/>
                                        </div>
                                    </div>
                                    <img src="https://wework.qpic.cn/bizmail/TQ3FibfIEwrqicw9QllFKKAyXhuV5GaLwlr2biczNDdn4QEa9mfibEzBwg/0" class="head"/>
                                <!-- </div> -->
                            </div>
                            </div>
                        </div>
                    </div>
                    <div class="arr-content">
                        <div class="item" v-for="(item,index) in msgList" :key="index">
                            <template v-if="item._id">
                                <span style="padding-right:20px;color:#666;">{{item.title||item.content.title}}</span> 
                                <span style="padding-right:20px;color:#666;">{{formatContactMsg(item.type)}}</span>
                                <a-icon type="close" :style="{fontSize:'12px',color:'#999999'}" @click="handleDelete(index)"/>
                            </template>
                        </div>
                    </div>
                </div>
            </div>  
        </a-modal>
    </div>
    
</template>
<script>
import {
    mapState
  } from 'vuex'
  import { TreeSelect } from 'ant-design-vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
    name:'send-more-2',
    data(){
        return{
            SHOW_PARENT,
            columns:[
                {
                title: '头像',
                key: 'avatar',
                dataIndex: 'avatar',
                scopedSlots: { customRender: 'avatar' },
                },
                {
                title: '姓名',
                key: 'username',
                dataIndex: 'username',
                },
                {
                title: '账号',
                key: 'acctid',
                align:'center',
                dataIndex: 'acctid',
                }
            ],
            tableData:[],
            arrList:[],
            selectList:[],
            msgList:[],
            loading:false,
            show:false,
            selectedRowKeys: [],
            type:'write',
            sendText:'',
            activeTab:'sendText',
            searchParam:'',
            pageNum:1,
            pageSize:10,
            hasNext:false,
            hasPrev:false,
            resList:[]
        }
    },
    computed: {
        ...mapState(['mqttInit','company','tagTree'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.getCollocationList()
                }
            },
            immediate:true
        },
        sendText(val){
            if(val!==""){
                
                let obj={
                    company: this.company,
                    content: val,
                    create_time: new Date().getTime(),
                    is_delete: false,
                    type: 'sendText',
                }
                if(this.msgList[0] && this.msgList[0]._id){
                    this.msgList.unshift(obj)
                }else{
                    this.$set(this.msgList,0,obj)
                }
                
            }else{
                this.msgList.splice(0,1)
            }
        },
    },
    methods:{
        handleGetProducts(){
             let payload = {
                msgId:'listProducts',
                data:{
                    pageSize:this.pageSize,
                    pageNum:this.pageNum                }
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/media/listProducts",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.resList = data.data.res
                        let count = data.data.count
                        this.hasPrev = this.pageNum>1
                        this.hasNext = count>this.pageNum*this.pageSize
                    }
                },
                this
            );
        },
        handleDelete(index){
            this.msgList.splice(index,1)
        },
        handleClose(){
            // console.log('close')
            // this.userLabel = false
            this.arrList = []
            // this.selectedRowKeys = []
            this.sendText = ""
            this.msgList = []
        },
        getSeleted(){
            if(this.arrList.length==0){
                return
            }
            let arr = []
            this.arrList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.id))
                }else{
                    arr.push(item)
                }
            })
            this.selectList = arr
        },
        onChange(e){
            if(e.target.value==='select'){
                this.searchParam = ''
                this.pageNum = 1
                this.handleGetMaterial()
            }
        },
        //点击选择
        handleClickUse(item){
            console.log(item)
            if(this.activeTab!=='sendProduct'){
                this.msgList.push(item)
            }else{
                let obj = {
                    _id:item._id,
                    type:'sendApp',
                    content:{
                        appid:'wx358b79d05ba58ea4',
                        ghid:'gh_7784efb20c22@app',
                        des:item.description,
                        image:item.attachments[0].url,
                        title:'WeComMall',
                        thumburl:'http://mmbiz.qpic.cn/mmbiz_png/BVHEcqfjR8j2HWibruibm7MtroO41hZyexdkydyCCOwHVm6uUaUFAwEk2GicGvQulaIErGXLqv8GZTl4n5UXSlsfw/640?wx_fmt=png&wxfrom=200',
                        pagepath:`pages/productDetail/index.html?id=${item._id}&company=${this.company}`
                    }
                }
                this.msgList.push(obj)
            }      
        },
        handleClickSend(){
            this.$confirm({
              title: '提示',
              content: `是否确认发送这条消息？(为了合理运营，尽量不要频繁的群发，以防客户流失)`,
              onOk:()=>{
                  if(this.msgList.length>0){
                      this.msgList.forEach((item,index)=>{
                          setTimeout(()=>{
                              this.handleSendItem(item)
                          },index * 1000)
                      })
                      this.$message.success('群发成功')
                      this.show = false
                  }else{
                      this.$message.warning('请选择或填写需要发送的内容')
                  }
              }})
        },
        handleSendItem(item){
            let payload = {
                msgId:'taskSendMore',
                data: {
                    msgType:item.type,
                    user_ids:this.selectedRowKeys,
                    content:item.content
                },
            };
            if(this.selectList.length>0){
                payload.data.label = this.selectList
            }
            this.$mqtt.doPublish(
            {
            pubTopic: "scrm/messageTask/fetch",
            payload,
            },
            (topic, data) => {
                if(data.success){
                    // if(data.failed.length===0){
                        
                    // }else{
                    //     this.$notification.open({
                    //         message: '未发送成功账号',
                    //         description:`账号：${data.failed.toString()}}`,
                    //         duration: 0,
                    //     });
                    // }
                    
                }else{
                    // this.$message.success('网络有点小波动，请稍后再试')
                }
            },
            this
        );
        },
        //点击上一页
        handleClickPrev(){
            this.pageNum -- 
            this.handleGetMaterial()
        },
        //点击下一页
        handleClickNext(){
            this.pageNum ++ 
            this.handleGetMaterial()
        },
        handleClickSearch(value){
            this.searchParam = value
            this.pageNum = 1
            this.handleGetMaterial()
        },
        handleChangeTab(val){
            this.activeTab = val
            this.pageNum = 1
            this.searchParam = ''
            this.handleGetMaterial()
        },
        onSelectChange(selectedRowKeys,row){
            // console.log(selectedRowKeys,row)
            this.selectedRowKeys = selectedRowKeys
        },
        handleClickSendMore(){
            // console.log(this.selectedRowKeys)
            this.selectList = []
            this.arrList = []
            this.show = true
        },
        getCollocationList(){
            if(!this.mqttInit){
                this.$message.info('连接中...')
                return
            }
            this.loading = true
            let payload = {
                msgId:'getUserList',
                data: {
                    pageSize:100,
                    pageNum:1,
                    robot_status:'2'
                },
            };
        // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/weUser/searchUserByPage",
                payload,
                },
                (topic, data) => {
                // console.log(data)
                if(data.code===200){
                    this.loading = false;
                    this.tableData = data.data.res;
                }
                
                },
                this
            );
        },
        handleConfirm(){
            if(!this.sendText){
                this.$message.warning('不能为空')
                return
            }
            
            let payload = {
                msgId:'taskSendMore',
                data: {
                    msgType:'sendText',
                    user_ids:this.selectedRowKeys,
                    content:this.sendText
                },
            };
            if(this.selectList.length>0){
                payload.data.label = this.selectList
            }
            this.show = false
        // console.log(payload)
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/messageTask/fetch",
                payload,
                },
                (topic, data) => {
                    if(data.success){
                        if(data.failed.length===0){
                            this.$message.success('群发成功')
                        }else{
                            this.$notification.open({
                                message: '未发送成功账号',
                                description:`账号：${data.failed.toString()}}`,
                                duration: 0,
                            });
                        }
                        
                    }else{
                        this.$message.success('网络有点小波动，请稍后再试')
                    }
                },
                this
            );
        },
        formatContactMsg(type){
            if(type == 'sendAudio'){
                return '[语音]'
            }else if(type == 'sendVideo'){
                return '[视频]'
            }else if(type == 'sendImage'){
                return '[图片]'
            }else if(type == 'sendLink'){
                return '[链接]'
            }else if(type == 'sendText'){
                return '[文本]'
            }else if(type == 'sendApp'){
                return '[小程序]'
            }
        },
        //查询素材
        handleGetMaterial(){
            if(this.activeTab!=='sendProduct'){
                let topic = 'scrm/materialMgtService/searchMaterialByPage'
                let payload = {
                    msgId:'searchMaterialByPage',
                    data: {
                        "type":this.activeTab,
                        "title":this.searchParam,
                        "pageNum":this.pageNum,
                        "pageSize":this.pageSize,
                    },
                };
                this.$mqtt.doPublish(
                    {
                    pubTopic: topic,
                    payload,
                    },
                    (topic, data) => {
                        this.resList = data.data.res
                        let count = data.data.count
                        this.hasPrev = this.pageNum>1
                        this.hasNext = count>this.pageNum*this.pageSize
                    },
                    this
                );
            }else{
                this.handleGetProducts()
            }
            
        }
    }
}
</script>
<style lang="scss" scoped>
.more-title{
    text-align: right;
    color: #F8AC30;
    font-size: 12px;
}
.main{
    display: flex;
    min-height: 500px;
    .left,.right{
        width: 50%;
    }
    .right{
        display: flex;
        flex-direction: column;
        align-items: center;
        .phone{
            height: 400px;
            width: 250px;
            border-radius: 10px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            .phone-title{
                height: 40px;
                background: #333;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 10px 10px 0px 0px;
            }
            .phone-content::-webkit-scrollbar {
                display: none;
            }
            .phone-content{
                height: 350px;
                padding: 10px;
                box-sizing: border-box;
                overflow: scroll;
                .message-img{
                    width: auto;
                    height: auto;
                    max-width: 150px;
                    border-radius: 4px;
                }
                .link-content{
                    width: 150px;
                    // height: 90px;
                    background-color: #fff;
                    padding: 10px;
                    border-radius: 4px;
                    border: 1px solid #eee;
                    border-radius: 5px;
                    .title{
                        height: 40px;
                        font-size: 14px;
                        color: #333;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        word-break: break-all;
                        word-wrap: break-word;
                        overflow: hidden;
                    }
                    .content{
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        .desc{
                            box-sizing: border-box;
                            height: 30px;
                            width: 140px; 
                            font-size: 12px;
                            color: #999;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            word-break: break-all;
                            word-wrap: break-word;
                            overflow: hidden;
                        }
                        .link-img{
                            width: 30px;
                            height: 30px;
                        }
                    }

                }
                .app-content{
                    width: 150px;
                    // height: 80px;
                    background-color: #fff;
                    padding: 10px;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    border: 1px solid #efefef;
                    position: relative;
                    .title{
                        height: 30px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        .logo-img{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                        }
                        .app-name{
                            font-size: 13px;
                            color:#999;
                            padding-left: 10px;
                        }
                    }
                    .content{
                        .desc{
                            color: #333;
                            font-size: 13px;
                            height: 25px;
                        }
                        .main-img{
                            width: 100%;
                            height: 70px;
                        }
                    }
                }
                .self-msg{
                    display: flex;
                    justify-content: flex-end;
                    margin-bottom: 20px;
                    .head{
                        margin-left: 10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 5px;
                    }
                    .msg{
                        box-sizing: border-box;
                        // height: 20px;
                        max-width: 200px;
                        word-break:break-word;
                        background: #A0E871;
                        padding: 10px;
                        font-size:14px;
                        color: #333;
                        border-radius: 3px;
                    }
                }
            }
        }
        .arr-content{
            width: 250px;
            .item{
                margin-top: 20px;
            }
            
        }
    }
}
    .form-title {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        padding: 0px 15px;
        margin-bottom: 10px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
    }
    .tab-view{
        margin-top: 20px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999;
        // border-bottom: 1px solid #e7e7e7;
        .tab-item{
            height: 50px;
            line-height: 50px;
            cursor: pointer;
        }
        .tab-item.active{
            color: #48A5A1;
            font-weight: bold;
        }
    }
    .tab-content{
        height: calc(100% - 55px - 60px - 35px);
        box-sizing: border-box;
        overflow-y: auto;
    }
    .img-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;

    }
    .search-content{
        box-sizing: border-box;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        position: relative;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        .icon-search{
            position: absolute;
            // top:22px;
            left:28px;
            color: #999;
        }
        .inp{
            box-sizing: border-box;
            border:0px;
            background-color: #DBD9D8;
            height: 30px;
            width: 200px;
            padding-left: 30px;
            border-radius: 3px;
        }
        .btn{
            background-color: #48A5A1;
            color: #fff;
            font-size: 12px;
            border-radius: 3px;
            border:0px;
            height: 30px;
            line-height: 30px;
            padding: 0px 10px;
            cursor: pointer;
        }
    }
    .btn-view{
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
        .btn{
            border: 0px;
            background-color:#48A5A1;
            color: #fff;
            width: 60px;
            height: 30px;
            border-radius: 3px;
            cursor: pointer;
        }
        .btn:disabled{
            background-color: #eee;
            color:#999;
            cursor: not-allowed;
        }
    }
    .text-item{
        padding: 10px;
        box-sizing: border-box;
        border-bottom:1px solid #efefef;
        position: relative;
        .text-title{
            font-size: 14px;
            color: #333;
            font-weight: bold;
            margin-bottom: 5px;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .text-desc{
            width: 400px;
            font-size: 12px;
            color: #999;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item{
        width: 75px;
        height: 95px;
        padding: 5px;
        border: 1px solid #efefef;
        border-radius: 2px;
        margin-right: 6px;
        margin-bottom: 6px;
        position: relative;
        .img{
            width: 100%;
            height: 62px;
        }
        .title{
            font-size: 12px;
            text-align: center;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
    }
    .img-item:nth-child(3n){
        margin-right: 0px;
    }
    .app-view,.product-view {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .product-item{
        width: 145px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
       
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
       .title{
            height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            .logo-img{
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .app-name{
                font-size: 13px;
                color:#999;
                padding-left: 10px;
            }
        }
        .content{
            width: 100%;
            .desc{
                width: 100%;
                color: #333;
                font-size: 13px;
                height: 19px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            }
            .main-img{
                width: 100%;
                height: 100px;
            }
        }
    }
    .app-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
       
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
       .title{
            height: 30px;
            line-height: 30px;
            display: flex;
            align-items: center;
            .logo-img{
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .app-name{
                font-size: 13px;
                color:#999;
                padding-left: 10px;
            }
        }
        .content{
            .desc{
                color: #333;
                font-size: 13px;
                height: 19px;
                white-space:nowrap;
                overflow:hidden;
                text-overflow:ellipsis;
            }
            .main-img{
                width: 100%;
                height: 100px;
            }
        }
    }
    
    .link-view{
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding: 10px 0px;
    }
    .link-item{
        width: 200px;
        // height: 80px;
        background-color: #fff;
        padding: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        border: 1px solid #efefef;
        position: relative;
        .title{
            // font-size: 16px;
            color: #333;
            height: 42px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            // line-height: 30px;
        }
        .use-btn{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            background-color: #2CA2FC;
            color: #fff;
            border: 0px;
            border-radius: 3px;
            padding: 3px 6px;
        }
        .content{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .desc{
                box-sizing: border-box;
               height: 50px;
               width: 140px; 
               font-size: 12px;
               color: #999;
               display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                word-break: break-all;
                word-wrap: break-word;
                overflow: hidden;
            }
            .link-img{
                width: 50px;
                height: 50px;
            }
        }
    }
</style>